import styled from "styled-components";

export const Container = styled.div`
  background-color: #c14481;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
`;

export const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  font-size: 1.5em;
  font-weight: bold;
  margin-right: 3rem;
  max-width: 500px;
  text-align: right;
`;

export const Instructions = styled.div`
  background-color: #1d1d1d;
  border-radius: 10px;
  padding: 10px;
  margin-top: 30px;
  
`;

export const QRCodeCenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 2;
`;

export const QRCode = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: #fff;
  border-radius: 10px;
  padding: 60px;
`;
