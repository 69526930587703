import React from "react";
import { QRCodeSVG } from "qrcode.react";
import * as S from "../styles";

function QRCode({ code }) {
  return (
    <S.Container>
      <S.QRCodeCenteredContainer>
        <S.Text>
          <img
            src={
              "https://uploads-ssl.webflow.com/62443b8315702250ce9e390d/62443c04718f404f79be83b6_logofullwhitesmall.png"
            }
            alt={"Sei de Cor"}
            style={{ marginBottom: 20}}
          />
          Live Player
          <S.Instructions>
            Acesse a Live pelo aplicativo e clique em conectar com código e
           escaneie o QRCode ao lado ➡
          </S.Instructions>
        </S.Text>
        <S.QRCode>
          <QRCodeSVG value={code} style={{ width: "40vh", height: "40vh" }} />
        </S.QRCode>
      </S.QRCodeCenteredContainer>
    </S.Container>
  );
}

export default QRCode;
