import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => (props.cor ? props.cor : "#000")};
  padding: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Text = styled.div``;

export const PlayerContainer = styled.div`
  flex: 1;
  background-color: #000;
  border-radius: 1rem;
  overflow: hidden;
`;

export const Player = styled.div``;

export const PlayerName = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 2rem;
  left: 2rem;
`;

export const PlayerScore = styled.div``;

export const EmpresaLogoOverlay = styled.div`
  position: absolute;
  bottom: 4rem;
  right: 4rem;
  width: 200px;
  height: 150px;
  object-fit: contain;
  background-image: ${(props) => (props.logo ? `url(${props.logo})` : "none")};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
`;
