import React from "react";
import ReactHlsPlayer from "react-hls-player";
import * as S from "./styles";
import MuxVideo from "@mux/mux-video-react";

function Room({ data }) {
  console.log(data);
  const playerRef = React.useRef();

  const { empresa, streamer, link } = data;
  const { cor, logo, nome } = empresa;
  //"https://stream.mux.com/DS00Spx1CV902MCtPj5WknGlR102V5HFkDe/high.mp4"
  let playbackId = link.replace("https://stream.mux.com/", "").split("/")[0];
  return (
    <S.Container cor={cor}>
      <S.PlayerName>{nome}</S.PlayerName>
      <S.PlayerScore></S.PlayerScore>
      <S.PlayerContainer>
        <MuxVideo
          controls={true}
          playbackId={playbackId}
          style={{
            width: "100%",
            height: "100%",
          }}
          streamType={"ll-live"}
          autoPlay
        />
        {/* <ReactHlsPlayer
          src={link}
          playerRef={playerRef}
          autoPlay={true}
          controls={false}
          onError={(e) => console.log("erro capturado", e)}
        /> */}
      </S.PlayerContainer>
      <S.EmpresaLogoOverlay logo={logo} />
    </S.Container>
  );
}

export default Room;
