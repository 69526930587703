import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue, remove } from "firebase/database";
import { useEffect, useState } from "react";
import uuid from "react-uuid";
import "./App.css";
import QRCode from "./QRCode";
import Room from "./Room";

const firebaseConfig = {
  apiKey: "AIzaSyC2Ac_AX31qqV0OXF1N1Q9o07tP88303Sw",
  authDomain: "seidecor-194519.firebaseapp.com",
  databaseURL: "https://seidecor-194519.firebaseio.com",
  projectId: "seidecor-194519",
  appId: "1:1073487642786:web:550c6023e9e8c3eb3597fb",
  measurementId: "G-D23J7V9QLR",
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

function App() {
  const [state, setState] = useState({
    loading: true,
    live: null,
    code: 'f88cd9d9-1f4d-b3e6-adc8-8c078165eb0c'//uuid()
  });
  const { loading, live, code } = state;
  const refshow = ref(db, `/show/${code}`);

  useEffect(() => {
    console.log("escutando sala: ", code);
    startListening();
    return () => {
      stopListening();
    };
  }, []);

  function startListening() {
    onValue(refshow, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        console.log(data);
      }
      console.log(data);
      setState({ ...state, loading: false, live: data });
    });
  }

  function stopListening() {
    //refshow.off();
    // refshow.remove();
  }
  if (loading) {
    return <div>loading...</div>;
  }

  if (!live) {
    return <QRCode code={code} />
  }
  return <Room data={live} />;
}

export default App;
